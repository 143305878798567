.jumbo-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 4% 8% 4% 8%;
    border-radius: 12px;
    padding: 25px;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.52) 11.19%, rgba(255, 255, 255, 1.00) 100%);
}

.custom-button {
    width: auto;
    height: 60px;
    padding: 0 35px;
    font-size: 28px;
    background-color: #4879B2;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.2s ease-in-out;
}

.custom-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

.small-button {
    width: auto;
    height: 30px;
    padding: 0 14px 0 14px;
    font-size: 16px;
    font-weight: bold;
    background-color: #4879B2;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.avatar {
    width: 90px;
    border-radius: 50%;
    align-self: center;
}

@media (min-width: 500px) {
    .avatar {
        width: 200px;
    }
}

.title {
    font-size: 18px;
    text-align: center;
    margin-top: 3px;
    color: #6b6b70;
    align-self: center;
}

.spirit-sync-link {
    color: #2a70c8;
    text-decoration: none;
    font-weight: bold;
}

.spirit-sync-link:hover {
    font-size: 18px;
}

