body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-size: cover;
    background-attachment: fixed;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/liam-portfolio.appspot.com/o/bkgd.jpeg?alt=media&token=cc919a4f-b232-4fea-9a5c-932499516ab8');
    text-align: center;
}

.app-title {
    margin-top: 75px;
    margin-bottom: 50px;
    font-size: 76px;
    color: #0ccac4;
    font-family: 'Bigelow Rules', serif;
}

@media (max-width: 1180px) {
    body {
        background-image: linear-gradient(180deg, rgb(103, 173, 160) 0%, rgba(54, 139, 149) 0%);
    }
}
